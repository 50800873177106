/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../layout'

export default props => {

  return (
    <Layout>
      <div
        sx={{
          p: 3,
        }}>
        <h1>404: Error Page Not Found</h1>
      </div>
    </Layout>
  )
}
